<template>
  <div class="clearfix">
    <div class="container my-5" style="text-align: justify;">
      <div class="row">
        <div class="col-md-6">
          <h2 class="text-center">MISIÓN</h2>
          <img
            src="/img/circles/mision.jpg"
            class="img-thumbnail rounded-circle my-4 mx-auto d-block"
            width="150px"
            height="150px"
            >
            <p>
              <strong>MEDIDAS ESPECIALIZADAS Y CERTIFICACIONES LTDA – MEDICERT LTDA </strong>Ser reconocidos nacionalmente como un organismo de inspección confiable, independiente, y comprometido con la verificación de la conformidad de las instalaciones eléctricas de nuestros usuarios, con calidad, oportunidad y competencia evaluada, creciendo paulatinamente de acuerdo con el aumento de la capacidad instalada y la demanda de necesidades del mercado, como un organismo de inspección eficaz y con personal ético y calificado.
            </p>
        </div>
        <div class="col-md-6">
          <h2 class="text-center">VISIÓN</h2>
          <img
            src="/img/circles/vision.jpg"
            class="img-thumbnail rounded-circle my-4 mx-auto d-block"
            width="150px"
            height="150px"
            >
            <p>
              <strong>MEDIDAS ESPECIALIZADAS Y CERTIFICACIONES LTDA – MEDICERT LTDA </strong>espera obtener el reconocimiento por el organismo competente, posteriormente iniciará sus actividades de inspección con calidad, oportunidad y competencia evaluada, creciendo paulatinamente de acuerdo al aumento de la capacidad instalada y la demanda de necesidades del mercado en evaluación de instalaciones eléctricas, con el objetivo de ser reconocida en el ámbito nacional como un organismo de inspección con productos de excelente calidad y eficaz, destacando la buena atención al cliente como prioridad y el cuidado del medio ambiente, con el apoyo de un equipo de personas altamente capacitadas y calificadas.
            </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <h2 class="text-center">POLÍTICAS EN MATERIA DE CALIDAD</h2>
          <img
            src="/img/circles/calidad.jpg"
            class="img-thumbnail rounded-circle my-4 mx-auto d-block"
            width="150px"
            height="150px"
            >
            <p>
              <strong>MEDIDAS ESPECIALIZADAS Y CERTIFICACIONES LTDA – MEDICERT LTDA </strong>se compromete a entregar servicios de inspección oportunos y técnicamente confiables con base en el principio de la independencia de sus actividades como organismo de inspección tipo A y con el esfuerzo permanente de su personal para lograr la mejora continua de su sistema de gestión conforme a los requisitos de la norma ISO/IEC 17020 y del Reglamento Eléctrico Colombiano – RETIE, para satisfacer y aumentar las expectativas del sector eléctrico, del Ministerio de Minas y Energía y demás partes interesadas; asegurando la confidencialidad en sus actuaciones y la imparcialidad en sus decisiones bajo los lineamientos que gobiernan a los organismo de evaluación de la conformidad.
            </p>
            <p><strong>OBJETIVOS DE CALIDAD</strong></p>
            <p>Los objetivos de calidad definidos por <strong>MEDICERT LTDA</strong> son:</p>
            <ol>
              <li>Mantener y mejorar un sistema de gestión capaz de lograr que las actividades de inspección se desarrollen bajo los principios de imparcialidad, independencia y confidencialidad para asegurar resultados de inspección confiables.</li>
              <li>Garantizar la disponibilidad de equipos e infraestructura apropiados para la prestación del servicio con calidad técnica.</li>
              <li>Propender porque el personal que integra el organismo de inspección sea competente y trabaje bajo los lineamientos de confidencialidad e imparcialidad.</li>
              <li>Establecer un modelo de negocio que atienda a las partes interesadas con oportunidad y en los tiempos justos.</li>
              <li>Lograr que el organismo de inspección sea auto sostenible en el mediano plazo.</li>
            </ol>
        </div>
        <div class="col-md-4">
        <h2 class="text-center">POLÍTICAS DE IMPARCIALIDAD</h2>
          <img
            src="/img/circles/imparcialidad.jpg"
            class="img-thumbnail rounded-circle my-4 mx-auto d-block"
            width="150px"
            height="150px"
            >
            <p>
              <strong>Es política de MEDICERT LTDA:</strong>
            </p>
            <p><strong>OBJETIVOS DE CALIDAD</strong></p>
            <p>Los objetivos de calidad definidos por <strong>MEDICERT LTDA</strong> son:</p>
            <ul>
              <li>Asegurar que su gestión y su personal se encuentra libre de cualquier presión indebida interna o externa, comercial, financiera u otra que pueda afectar de forma adversa la calidad de su trabajo y, en general las actividades de inspección.</li>
              <li>Evitar intervenir en cualquier actividad que pueda disminuir la confianza de las actuaciones de <strong>MEDICERT LTDA</strong>, la competencia técnica de su personal, imparcialidad de sus actuaciones y el juicio o integridad operativa en sus decisiones.</li>
              <li>Analizar toda actividad nueva que se proyecte iniciar en el campo de su alcance, de tal forma que no afecte la independencia, imparcialidad e integridad del organismo.</li>
              <li>Abstenerse de ejercer presión al personal de <strong>MEDICERT LTDA</strong> sobre los servicios de inspección prestados a clientes y sobre los resultados de inspección generados, con el fin de cumplir con necesidades particulares o de interés comercial, principalmente por efecto de la necesidad tener suministro del servicio de energía.</li>
            </ul>
            <div class="text-center">
              <b-link href="/docs/politica_de_imparcialidad.pdf" target="_blank">
                <span class="text-danger "><font-awesome-icon icon="file-pdf" size="6x" /></span>
                <br>
                <span class="text-black-50" >Política de imparcialidad Medicert Ltda.</span>
              </b-link>
            </div>
        </div>
        <div class="col-md-4">
          <h2 class="text-center">NUESTROS VALORES CORPORATIVOS</h2>
          <img
            src="/img/circles/valores.jpg"
            class="img-thumbnail rounded-circle my-4 mx-auto d-block"
            width="150px"
            height="150px"
            >
            <p>
              En nuestros valores empresariales nos basamos en 5 puntos básicos para aplicarlos en nuestros servicios y tareas al desarrollar:
            </p>
            <p><strong>Confianza: </strong>MEDICERT LTDA asegura la calidad de su trabajo y la competencia de su personal para garantizar que los resultados de sus actividades de inspección optimicen los proyectos y obras de sus clientes.</p>
            <p><strong>Imparcialidad: </strong>MEDICERT LTDA mantiene ecuanimidad y una actitud no discriminatoria evitando el sesgo frente a quienes solicitan sus servicios de inspección de tal forma que se mantiene el mismo trato a cada cliente.</p>
            <p><strong>Responsabilidad: </strong>MEDICERT LTDA responde por las actuaciones que, en su nombre efectué su grupo de trabajo, independientemente del tipo de vinculación o actividad acordada entre el personal y la empresa o la asignada por la misma para el logro de sus objetivos.</p>
            <p><strong>Eficiencia: </strong>MEDICERT LTDA busca que sus servicios de inspección sean adecuados y suficientes con lo esperado por las partes interesadas en términos de tiempo y oportunidad, entrega de resultados y atención de sus necesidades teniendo en cuenta en todo momento la calidad del servicio.</p>
            <p><strong>Disciplina: </strong>MEDICERT LTDA busca que sus actividades sean desarrolladas por personal comprometido con el organismo de inspección en el cumplimiento de sus directrices y políticas, siendo autónomo para que, en conjunto como una organización, se obtenga la misión esperada.</p>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: 'Empresa',
  mounted () {
    this.$store.commit('setActualPage', 'Empresa')
  }
}
</script>

<style>
</style>
